import { ref, watchEffect } from "vue";
import {
  computePosition,
  shift,
  offset,
  autoUpdate,
  autoPlacement,
  flip,
} from "@floating-ui/dom";

// Para futuras mejoras o añadir alguna funcionalidad revisar https://floating-ui.com/docs/tutorial
export function useFloating(
  reference,
  floating,
  options,
  middlewareToggle = ["autoPlacement", "shift", "flip"],
  allowedPlacements = ["top-start", "top-end", "bottom-start", "bottom-end"]
) {
  const floatingStyles = ref({
    left: "0px",
    top: "0px",
    position: "absolute",
  });
  const cleanup = ref(false);
  const valueOffset = options?.offset || 0;
  watchEffect(
    () => {
      if (cleanup.value && typeof cleanup.value === "function") {
        cleanup.value();
      }

      if (!reference.value || !floating.value) return;

      const referenceElement = reference.value.$el || reference.value;
      const floatingElement = floating.value.$el || floating.value;

      const middleware = middlewareToggle
        .map(middlewareName => {
          switch (middlewareName) {
            case "autoPlacement":
              return autoPlacement({ allowedPlacements });
            case "shift":
              return shift();
            case "flip":
              return flip();
            default:
              return null;
          }
        })
        .filter(Boolean);

      middleware.unshift(offset(valueOffset));

      function updatePosition() {
        computePosition(referenceElement, floatingElement, {
          ...options,
          middleware,
        }).then(({ x, y }) => {
          floatingStyles.value = {
            left: `${x}px`,
            top: `${y}px`,
            position: "absolute",
          };
        });
      }

      cleanup.value = autoUpdate(referenceElement, floatingElement, () => {
        requestAnimationFrame(updatePosition);
      });
    },
    { flush: "post" }
  );

  return {
    floatingStyles,
  };
}
